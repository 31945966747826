import React from "react";
import { Button, Col, Form } from "react-bootstrap";

const offsetArr = [10,20 , 50 , 100, 250 , 500, 1000, 5000]

export const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
  
    const selectStyleLeftBorder = {
      borderRadius: "0px",
      fontFamily: "Poppins",
      fontsize: "16px",
      borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
      borderRadius: "0px",
      borderLeft: "none", // Remove right side border
      borderTop: "none", // Remove top border
      borderBottom: "none", // Remove bottom border
      fontFamily: "Poppins",
      fontsize: "16px",
    };
    const selectStyleBorder = {
      border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontsize: "16px",
    };
    const selectStyle = {
      border: "none", // Remove the border
      fontFamily: "Poppins",
      fontsize: "16px",
    };
    return (
      <div
        className="row"
        style={{
          display: "flex",
          alignItems: "end",
          width: "100%",
          margin: "10px 0px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontFamily: "Poppins" }}>
          <strong>{`Showing ${count} of ${total} entries`}</strong>
        </div>
  
        <div className="d-flex btn-group p-1" style={selectStyleBorder}>
          <select
            style={selectStyleLeftBorder}
            className=" d-flex justify-content-end "
            aria-label="offset"
            onChange={(e) => {
              callback2(e.target.value);
            }}
          >
            {offsetArr.map((offset) => (
              <option value={offset} className="d-flex justify-content-end">
                {offset}
              </option>
            ))}
          </select>
          {page - 2 > 0 ? (
            <Button
              onClick={() => {
                callback(page - 1);
              }}
              className="rounded-0 bg-white text-dark border-white"
            >
              {"<<"}
            </Button>
          ) : null}
  
          {page - 1 > 0 ? (
            <Button
              onClick={() => {
                callback(page - 1);
              }}
              className="rounded-0 bg-white text-dark border-white underline-text"
            >
              {" "}
              {(page - 1).toString()}
            </Button>
          ) : null}
  
          <Button className="bg-white text-primary " style={selectStyle}>
            {" "}
            {page.toString()}
          </Button>
  
          {/* Next And Previous Button Code */}
          {page + 1 <= limit ? (
            <Button
              onClick={() => {
                callback(page + 1);
              }}
              className="rounded-0 bg-white text-dark border-white"
            >
              {">>"}
            </Button>
          ) : null}
        </div>
      </div>
    );
  };