import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Form, FormControl } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
const arr = [{ label: "Name", value: "Name" }];

const DecodeQR = () => {
  const [selectedTransactionType, setSelectedTransactionType] = useState("");

  const location = useLocation();
  const qrCodeUrl =
    "https://portal.getepay.in:8443/getepayPortal/merchantInvoice/showQr?qrPath=/media/shared//home/bahetymradul/donotdelete/projectData/VPA/QRIMAGE/DynamicQRCode/1704449963607/merchant1511219.augp@aubank.png";

  const getQR = async (img) => {
    const qrcode = require("qrcode-decoder");
    const qr = new qrcode();

    const result = await qr.decodeFromImage(img);

    console.log(result);
  };

  useEffect(() => {
    // Import the required library
  

    // URL of the QR code image
    // const qrCodeUrl = "https://example.com/path/to/qr_code.png";

    // Create an image element
    const img = new Image();

    // Load the QR code image
    img.onload = function () {

        getQR(img);
    };

    // Set the image source to the QR code URL
    img.src = qrCodeUrl;
  }, []);

  return (
    <>
      <div>Hii </div>
    </>
  );
};

export default DecodeQR;
