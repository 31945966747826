import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
function CreateMerchant() {
  const { user } = useAuth();
  const [passwordError, setPasswordError] = useState("");
  const [data, setData] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });

  const [formError, setFormError] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const clearForm = () => {
    setFormData({
      businessname: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobile: "",
    });
    setPasswordError("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setFormError({ ...formError, password: ["Password does not match"] });
      return;
    }
    // clearForm();
    createMerchantApi();
  };

  const createMerchantApi = async () => {
    console.log("hiii");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        parentId: user.merchantId,
        companyName: formData.businessname,
        usertype: "1",
        mobile: formData.mobile,
        password: formData.password,
        email: formData.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://cubexis.in/api/merchantRegistration2.php",
        requestOptions
      );

      const data = await res.json();

      console.log(data);

      if (data.status === "00") {
        Swal.fire("Success", "Merchant Created Successfully");
        clearForm();
      } else {
        if (data.errors) {
          setFormError(data.errors);
        }
      }
    } catch (error) {
      console.error("Error fetching Form data:", error);
    }
  };

  console.log(formData);

  return (
    <div
      className=" p-0 p-lg-5"
      style={{
        backgroundColor: "#F1EFEF",
        fontFamily: "poppins",
        height: "93vh",
      }}
    >
      <div
        className="full-width-container text-dark bg -light p-3 m-3  "
        style={{
          border: "0px solid #000000",
          borderTop: "0px solid #000000",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <div className="row p-0  p-lg-5">
          <div className="col-3"></div>
          <div
            className="col-12 p-3 p-lg-4 col-lg-6 bg-white shadow"
            style={{ borderRadius: "10px" }}
          >
            <form className="row">
              <div className="mb-3 col-12  d-flex align-items-center justify-content-center text-center">
                <div
                  className=""
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                >
                  {" "}
                  Create Merchant
                </div>
              </div>

              <div className="mb-3 col-12 col-lg-6 ">
                <label className="form-label">
                  Email{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.email}
                  placeholder="Enter Email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
                {formError.email && (
                  <p style={{ color: "red" }} className="error-message">
                    {formError.email}
                  </p>
                )}
              </div>
              <div className="mb-3 col-12 col-lg-6 ">
                <label className="form-label">
                  Mobile Number{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={formData.mobile}
                  placeholder="Enter Mobile number"
                  onChange={(e) =>
                    setFormData({ ...formData, mobile: e.target.value })
                  }
                  required
                />
                {formError.mobile && (
                  <p style={{ color: "red" }} className="error-message">
                    {formError.mobile}
                  </p>
                )}
              </div>
              <div className="mb-3 col-12 col-lg-6 ">
                <label className="form-label">
                  Password{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.password}
                  placeholder="Enter Password"
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required
                />
                {formError.password &&
                  formError.password.map((err) => (
                    <p style={{ color: "red" }} className="error-message">
                      {err}
                    </p>
                  ))}
              </div>
              <div className="mb-3 col-12 col-lg-6 ">
                <label className="form-label">
                  Confirm Password{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label">
                    Business Name{" "}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Business Name"
                    value={formData.businessname}
                    onChange={(e) =>
                      setFormData({ ...formData, businessname: e.target.value })
                    }
                    required
                  />
                  {formError.businessname && (
                    <p style={{ color: "red" }} className="error-message">
                      {formError.businessname}
                    </p>
                  )}
                </div>
                <div className="col">
                  
                </div><div className="row mt-4">
                    <div className="col">
                      {" "}
                      <button
                        className=" btn btn-block  text-white bg-success m-2"
                        style={{
                          width: "100%",
                        }}
                        onClick={handleSubmit}
                      >
                        Create Merchant
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className=" btn btn-block  text-white bg-danger m-2"
                        style={{
                          width: "100%",
                        }}
                        onClick={clearForm}
                      >
                        Clear Form
                      </button>
                    </div>
                  </div>
              </div>
            </form>
          </div>
          <div className="col-3"></div>
        </div>
      </div>
    </div>
  );
}

export default CreateMerchant;
