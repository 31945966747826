import React, { useContext, useEffect, useRef, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Transactions, { Payin, Payout } from "./Transactions";
import { urls } from "./devUrls";
import { twoDecimalPlaces } from "../utils/utilFuctions";
import { useAuth } from "../../context/AuthContext";

const Profile = () => {
    const [details, setDetails] = useState({});
    let {
        user2: info,
        login2 : setMyAccountInfo,
    } = useAuth();
    const { GetUserProfileInfo: GetUserProfileInfoUrl } = urls;
    if (info == null) {
        info = {};
    }
    const history = useHistory();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [balance, setBalance] = useState({});


    useEffect(() => {
        getAllVirtualPayments();
    }, []);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
      }

    const dateFormatter = (date = new Date()) => {
        const mm = date.getMonth() + 1;
        return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const getAllVirtualPayments = async () => {
        const accessToken = sessionStorage.getItem("accessToken");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Cookie",
            ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
        );

        var raw = JSON.stringify({
            "toDate": dateFormatter(startDate),
            "fromDate":dateFormatter( getDatePreviousMonth()),
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            // setTransactionLoaderState(true)
            const res = await fetch(
                "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
                requestOptions
            );
            console.log("response", res);
            if (res.status == 401) {
             //   removeMyAccountInfo();
            } else if (res.ok) {
                const data = await res.json();
                console.log(data);
                if (data && data.length > 0) {

                    const dataLoad = data
                        .map((acc, i) => {
                            if (i == 0) {
                                const ccB = twoDecimalPlaces(Number(acc.opening_bal) + Number(acc.today_topup) + Number(acc.today_refund) - Number(acc.payout_today) - Number(acc.payout_fee) - Number(acc.payin_fee) + Number(acc.settlement_today))
                                setBalance({ paymentAPIBalance: ccB })
                            }
                            return {
                                ...acc,
                                upi: `${acc.beneAccountNo}@yesbankltd`,
                                date: new Date(acc.createdOn),
                                closingBal: twoDecimalPlaces(Number(acc.opening_bal) + Number(acc.today_topup) + Number(acc.today_refund) - Number(acc.payout_today) - Number(acc.payout_fee) - Number(acc.payin_fee) + Number(acc.settlement_today))
                            };
                        });
                    dataLoad.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date);
                    });
                    console.log(dataLoad);
                    // setData(dataLoad);
                    //  setDataToDisplay(dataLoad);
                }
            }
            // setTransactionLoaderState(false)
            // setPayments(JSON.parse(body).data);
        } catch (error) {
            // setTransactionLoaderState(false)
            console.log("401 catched", error);
        }
    };

    useEffect(() => {
        getAccountInfo();
    }, []);

    const getAccountInfo = async () => {
        const accessToken = sessionStorage.getItem("accessToken");
        const userId = sessionStorage.getItem("userId");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Cookie",
            ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
        );

        var raw = JSON.stringify({
            userId: userId,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const res = await fetch(GetUserProfileInfoUrl, requestOptions);
            const data = await res.json();
            setMyAccountInfo(data.data);
        } catch (error) { }
    };

    if (info == null) {
        history.push("/error-pages/error-404");
        return;
    }

    return (
        <div style={{ background: "#f2f6fc" }}>

            <div
                style={{
                    padding: "10px",
                    background: "#E4EFFC",
                    border: "2px solid #3568FF",
                }}
                className="card m-1"
            >
                <h5 style={{ color: "#3568FF" }}>2 Step Verification</h5>
                <p style={{ color: "#3568FF" }}>
                    Add an extra layer of security to your account by using a
                    one-time verification code in addition to your password each
                    time you log in.
                </p>
            </div>
            <div className=" m-1 p-0">
                <div className="row p-1 mt-3" style={{ background: "white" }}>
                    <div className="col-7 ">
                        <div className="row m-0 mb-2 pt-2">
                            <div className="col p-0 d-flex align-items-center">
                                <h5>
                                    <b>Merchant ID:{info?.userId}</b>
                                </h5>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <Button
                                    style={{
                                        border: "1px solid",
                                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                                        color: "rgba(43, 193, 85, 1)",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                    }}
                                >
                                    Change Password
                                </Button>
                            </div>
                        </div>
                        <hr className="p-0 m-0" />
                        <div className="row p-0 m-0 mt-1 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Contact Name{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.name}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Contact Email{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.email}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Contact Number{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.phoneNumber}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Business Name{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.businessName}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Business Type{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.businessType}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Registration Date{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.createdOn}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Registration By{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.createdBy}
                            </p>
                        </div>
                        {/* <div className="row p-0 m-0 ">
          <p className="col" style={{fontSize:"1rem"}}> Account Activated On  </p>
          <p className="col d-flex justify-content-end" style={{fontSize:"1rem" , color:" rgba(0, 0, 0, 0.5)"}}>{info.department}</p>
        </div> */}
                        {/* <div className="row p-0 m-0 ">
          <p className="col" style={{fontSize:"1rem"}}> Account Access  </p>
          <p className="col d-flex justify-content-end" style={{fontSize:"1rem" , color:" rgba(0, 0, 0, 0.5)"}}>{info.department}</p>
        </div> */}
                        {/* <div className="row p-0 m-0 ">
          <p className="col" style={{fontSize:"1rem"}}> Business Website/App details </p>
          <p className="col d-flex justify-content-end" style={{fontSize:"1rem" , color:" rgba(0, 0, 0, 0.5)"}}>{info.department}</p>
        </div> */}
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Additional Business Website/App{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.additionalWebsiteDetail}
                            </p>
                        </div>
                        <div className="row p-0 m-0 ">
                            <p className="col" style={{ fontSize: "1rem" }}>
                                {" "}
                                Limit Per Transaction{" "}
                            </p>
                            <p
                                className="col d-flex justify-content-end"
                                style={{
                                    fontSize: "1rem",
                                    color: " rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                {info.limitPerTransaction}
                            </p>
                        </div>
                    </div>
                    <div className="col ">
                        <div
                            className="card p-0 "
                            style={{ background: "#F9FAFB" }}
                        >
                            <div className="row m-0 mx-2 mb-2 pt-2">
                                <div className="col  p-0 d-flex align-items-center">
                                    <h5>
                                        <b>Settlement Detail</b>
                                    </h5>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <h5
                                        style={{
                                            color: "rgba(43, 193, 85, 1)",
                                        }}
                                    >
                                        View Settlement Schedule
                                    </h5>
                                </div>
                            </div>
                            <hr className="p-1 m-0" />
                            <div className="row p-0 m-0 mt-1 ">
                                <h5 className="col ">Current Balance</h5>

                                <p
                                    className="col d-flex justify-content-end"
                                    style={{
                                        fontSize: "1rem",
                                        color: " rgba(0, 0, 0, 0.5)",
                                    }}
                                >
                                    {balance.paymentAPIBalance}
                                </p>
                            </div>
                        </div>
                        <div
                            className="card mt-3 p-2"
                            style={{ background: "#F9FAFB" }}
                        >
                            <h5>
                                <b>Upgrade Merchant</b>
                            </h5>
                            <p style={{ fontSize: ".9rem" }}>
                                You can upgrade your account to a Merchant Account by
                                giving us your business name
                            </p>
                            <p>
                                <b>Business Name:</b>
                            </p>
                            <Form.Control></Form.Control>
                            <div className="mt-3">
                                {" "}
                                <Button style={{ width: "8rem" }}>Primary</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">Support Details</h5>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Phone Number{" "}
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.supportPhoneNo}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Email ID
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.supportEmailId}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Website/Contact us link
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.supportWebsiteLink}
                    </p>
                </div>
            </div>

            <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">GST Details</h5>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        GST Detail{" "}
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.gstDetails}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Bumppy's GST Number
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.gstNumber}
                    </p>
                </div>
            </div>

            <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">Bank Account</h5>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        IFSC Code
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.ifscCode}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Account Number
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.accountNumber}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Beneficiary
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.beneficiaryName}
                    </p>
                </div>
            </div>

            <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">Access Management</h5>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Login Email
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.email}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Role
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.roleName}
                    </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                    <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Beneficiary
                    </p>
                    <p
                        className="col d-flex "
                        style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        {info.beneficiaryName}
                    </p>
                </div>
            </div>

            {/* <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard
                label={`Merchant Id: ${info.vAccountNumber}`}
                value={"Change Password"}
              />

              <DetailCard
                label={"Contact Name"}
                value={info.name ? info.name : "-"}
              />
              <DetailCard
                label={"Contact Email"}
                value={info.email ? info.email : "-"}
              />
              <DetailCard
                label={"Contact Number"}
                value={info.phoneNumber ? info.phoneNumber : "-"}
              />
              <DetailCard
                label={"Business Name"}
                value={info.businessName ? info.businessName : "-"}
              />
              <DetailCard
                label={"Business Type"}
                value={info.businessType ? info.businessType : "-"}
              />
              <DetailCard
                label={"Registration Date"}
                value={info.createdOn ? info.createdOn : "-"}
              />
              <DetailCard label={"Registered By"} value={"--"} />
              <DetailCard
                label={"Account Activated On"}
                value={" Aug 12 2019, 01:03 pm"}
              />
              <DetailCard label={"Account Access"} value={"Complete"} />
              <DetailCard
                label={"Business Website/App details"}
                value={"https://bumppy.com"}
              />
              <DetailCard
                label={"Additional Business Website/App"}
                value={
                  info.additionalWebsiteDetail
                    ? info.additionalWebsiteDetail
                    : "-"
                }
              />
              <DetailCard
                label={"Limit per transaction"}
                value={
                  info.limitPerTransaction ? info.limitPerTransaction : "-"
                }
              />
              {info.contactName ? (
                <DetailCard label={"Contact Name"} value={info.contactName} />
              ) : null}
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"Support Details"} />

              <DetailCard
                label={"Phone No"}
                value={info.supportPhoneNo ? info.supportPhoneNo : "-"}
              />
              <DetailCard
                label={"Email id"}
                value={info.supportEmailId ? info.supportEmailId : "-"}
              />
              <DetailCard
                label={"Website/Contact us link"}
                value={
                  info.supportWebsiteLink ? info.supportWebsiteLink : "-"
                }
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"GST Details"} />

              <DetailCard
                label={"GST Details"}
                value={info.gstDetails ? info.gstDetails : "-"}
              />
              <DetailCard
                label={"Bumppy's GST Number"}
                value={info.gstNumber ? info.gstNumber : "-"}
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"Bank Account"} />

              <DetailCard
                label={"IFSC Code"}
                value={info.ifscCode ? info.ifscCode : "-"}
              />
              <DetailCard
                label={"Account Number"}
                value={info.accountNumber ? info.accountNumber : "-"}
              />
              <DetailCard
                label={"Beneficiary"}
                value={"Bumppy Media Pvt Ltd"}
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailCard
                label={"Login Email"}
                value={"sumitvermacr7@gmail.com"}
              />
              <DetailCard
                label={"Role"}
                value={info.roleName ? info.roleName : "-"}
              />
              <DetailCard
                label={"Beneficiary"}
                value={"Bumppy Media Pvt Ltd"}
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"Upgrade Merchant"} />

              <div style={{ padding: "0px" }} className="card">
                <div style={{ padding: "8px" }} className="card-body">
                  <span className="row justify-content-center">
                    You can upgrade your account to a Merchant Account by
                    giving us your business name
                  </span>

                  <div className="row justify-content-center">
                    <label
                      style={{ margin: "5px", padding: "10px" }}
                      htmlFor="exampleInputtext"
                      className="text-center"
                    >
                      Business Name:
                    </label>
                    <Form.Control
                      style={{ margin: "10px" }}
                      type="email"
                      className="col-sm-3 form-control"
                      id="exampleInputtext"
                      placeholder="Acme Inc."
                    />
                    <button
                      style={{ margin: "10px" }}
                      type="button"
                      className="btn btn-primary btn-fw"
                    >
                      Primary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard
                value={"View Settlement Schedule"}
                label={"Settlement Details"}
              />

              <DetailCard label={"Current Balance"} value={"₹ 0.00"} />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard label={" Purpose Code and FIRC"} />

              <div style={{ padding: "8px" }} className="card">
                <div style={{ padding: "0px" }} className="card-title">
                  <span
                    style={{ padding: "0px" }}
                    className="text-muted text-center text-sm-left d-block d-sm-inline-block"
                  >
                    Purpose Code
                  </span>
                  <span
                    style={{ padding: "0px" }}
                    className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"
                  >
                    <button type="button" className="btn btn-link btn-fw ">
                      Select Code
                    </button>
                  </span>
                </div>
              </div>

              <DetailCard label={"FIRC Certificate"} value={"Coming Soon"} />
            </div>
          </div> */}

        </div>
    );
};


export default Profile;
