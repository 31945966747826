import React from "react";
import Lottie from 'lottie-react';
import Loader1 from '../../assets/animation/loader.json';
import Loader2 from '../../assets/animation/loader2.json';
import Loader3 from '../../assets/animation/loader3.json';
import LoadingOverlay from "react-loading-overlay";

const TransactionLoader = ({ children, isActive, text }) => {
  // Array of loader animations
  const loaders = [Loader1, Loader2, Loader3];

  // Randomly select a loader
  const randomLoader = loaders[Math.floor(Math.random() * loaders.length)];

  return (
    <LoadingOverlay
    styles={{height: '100vh'}}
      active={isActive}
      spinner={<Lottie style={{ height: '200px' }} animationData={randomLoader} />}
      text={text}>
      {children}
    </LoadingOverlay>
  );
};

export default TransactionLoader;
