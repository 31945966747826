  import React, { useState, useEffect } from "react";
  import { Link, Redirect } from "react-router-dom";
  import { urls } from "./devUrls";
  import { useLocation } from "react-router-dom";
  import { useAuth } from "../../context/AuthContext";
  import MyAccount2 from "./MyAccount2";

  export default function Developer() {
    const { user, login2, user2 } = useAuth();
    const { login: loginUrl } = urls;
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [passShow, setPassShow] = useState(false);
    const [formErrors, setFormErrors] = useState({
      username: "",
      password: "",
    });
    const [error, setError] = useState("");

    const merchantString = sessionStorage.getItem("merchant");
    const merchant = JSON.parse(merchantString);


    const location = useLocation();
    const payout_username =merchant?.payout_username;
    const payout_password = merchant?.payout_password;

    const { GetUserProfileInfo: GetUserProfileInfoUrl } = urls;
    // get my account details
    const getAccountInfo = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      const userId = sessionStorage.getItem("userId");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
      );

      var raw = JSON.stringify({
        userId: userId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {

        const res = await fetch(GetUserProfileInfoUrl, requestOptions);
        const data = await res.json();
        login2(data.data);
      } catch (error) {
      }
    };
    const login = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
      );

      var raw = JSON.stringify({
        email: payout_username || user.payout_username,
        password: payout_password || user.payout_password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const result = await fetch(loginUrl, requestOptions);
        if (result.ok) {
          const body = await result.text();
          const data = JSON.parse(body);
          if (data.userName != undefined) {
            sessionStorage.setItem("userName", data.userName);
          }
          if (data.role != undefined) {
            sessionStorage.setItem("role", data.role);
          }
          if (data.accessToken != undefined && data.userId != undefined) {
            sessionStorage.setItem("userId", data.userId);
            // setToken(data.accessToken);
            sessionStorage.setItem("accessToken", data.accessToken);
            getAccountInfo();
          }
          if (data.refreshToken != undefined) {
            sessionStorage.setItem("refreshToken", data.refreshToken);
          }
          sessionStorage.setItem(
            "basic_auth",
            JSON.stringify({ username: userName, password })
          );
          setRedirect(true);
        } else {
          setInvalid(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const showPasswordClick = () => {
      setPassShow(!passShow);
    };

    useEffect(() => {
      login();
    }, [])
    return (
      user2?.userId ? <MyAccount2 /> : (
        <div className="row justify-content-center">
          <div className="col-8 col-sm-5 col-md-4 card">
            <div
              style={{ background: "#FFF" }}
              className="auth-form-transparent text-left p-4"
            >
              <div className="brand-logo">
                <strong>BUMPPY</strong>
              </div>
              <h4>Developer Login</h4>
              <h6 className="font-weight-light">Happy To See You</h6>
              <form className="pt-3">
                <div className="form-group">
                  <label>Username or Mobile number</label>
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-account-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-lg border-left-0 no-glow"
                      id="exampleInputEmail"
                      placeholder="Username"
                      onChange={(e) => {
                        setError("");
                        setUserName(e.target.value);
                      }}
                    />
                  </div>
                  <p className="text-danger text-left p-0">
                    {formErrors.username}
                  </p>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      type={passShow ? "text" : "password"}
                      className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                      id="exampleInputPassword"
                      placeholder="Password"
                      onChange={(e) => {
                        setError("");
                        setPassword(e.target.value);
                      }}
                    />
                    <div className="input-group-prepend bg-transparent">
                      <span
                        className="input-group-text bg-transparent border-left-0 no-glow"
                        onClick={showPasswordClick}
                        style={{ borderRadius: "4px" }}
                      >
                        <i
                          className={`mdi text-primary ${passShow ? "mdi-eye" : "mdi-eye-off"
                            }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="text-danger text-left p-0">
                    {formErrors.password}
                  </p>
                </div>

                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      <i className="input-helper"></i>
                      Keep me signed in
                    </label>
                  </div>
                  <a
                    href="!#"
                    onClick={(event) => event.preventDefault()}
                    className="auth-link text-muted"
                  >
                    Forgot password?
                  </a>
                </div>
                {invalid ? (
                  <span className="text-danger">Invalid Username or Password</span>
                ) : null}
                <div className="my-3">
                  <div
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={login}
                  >
                    LOGIN
                  </div>
                </div>
                {/* <div className="mb-2 d-flex">
                    <button
                      type="button"
                      className="btn btn-facebook auth-form-btn flex-grow mr-1"
                    >
                      <i className="mdi mdi-facebook mr-2"></i>Facebook
                    </button>
                    <button
                      type="button"
                      className="btn btn-google auth-form-btn flex-grow ml-1"
                    >
                      <i className="mdi mdi-google mr-2"></i>Google
                    </button>
                  </div> */}
                {/* <div className="text-center mt-4 font-weight-light">
                      Don't have an account?{" "}
                      <Link to="/user-pages/register-2" className="text-primary">
                        Create
                      </Link>
                    </div> */}
              </form>
            </div>
          </div>
        </div>
      )
    );
  }

