import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.css";
import { useAuth } from "../context/AuthContext";
const NavigationBar = () => {
  const { logout, user } = useAuth();
  const [drop1, setDrop1] = useState("");

  //const { setIdValue } = useInternalContext();

  return (
    <Navbar
      className=" px-3 text-white bg-primary shadow"
      style={{ top: "0", left: "0", width: "100%" }}
      expand="lg"
    >
      <Navbar.Brand
        exact
        to="/dashboard"
        className="w-100 text-white "
        style={{ fontFamily: "poppins", fontSize: "20px" }}
      >
        {user.UserName}
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavLink
            to="/dashboard"
            className="nav-link text-white  "
            style={{ marginRight: "10px", marginLeft: "10px" }}
            activeClassName="active"
            as={NavLink}
          >
            Dashboard
          </NavLink>

          {/* settlement */}
          {user.merchantId === "2" && (
            <>
              <NavDropdown
                title="Settlement"
                id="Thumb"
                className={
                  drop1 == 1 ? "custom-dropdown active1" : "custom-dropdown"
                }
                activeClassName="active"
                style={{ marginRight: "10px", marginLeft: "10px" }}
              >
                <NavDropdown.Item as={NavLink} to="/SettlementProcess">
                  Settlement Process
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}

          {user.userType == "2" ||
            user.userType == "3" ||
            (user.userType == "4" && (
              <NavDropdown
                title="Merchant"
                id="Thumb"
                className={
                  drop1 == 1 ? "custom-dropdown active1" : "custom-dropdown"
                }
                activeClassName="active"
                style={{ marginRight: "10px", marginLeft: "10px" }}
              >
                <NavDropdown.Item as={NavLink} to="/merchant/create-merchant">
                  Create Merchant
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/merchant/view-merchant">
                  View All Merchant
                </NavDropdown.Item>
              </NavDropdown>
            ))}

          <NavDropdown
            title="Developer"
            id="Thumb"
            className={
              drop1 == 1 ? "custom-dropdown active1" : "custom-dropdown"
            }
            activeClassName="active"
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            <NavDropdown.Item as={NavLink} to="/developer/view-webhooks">
              Webhook
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/developer/view-ip">
              IP Merchant
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/developer/api-docs">
              API documentation
            </NavDropdown.Item>
          </NavDropdown>
          <NavLink
            to="/LoadRequest"
            className="nav-link text-white  "
            style={{ marginRight: "10px", marginLeft: "10px" }}
            activeClassName="active"
            as={NavLink}
          >
            Load+
          </NavLink>

          <NavDropdown
            title="Transactions"
            id="Thumb"
            className={
              drop1 == 1 ? "custom-dropdown active1" : "custom-dropdown"
            }
            activeClassName="active"
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            <NavDropdown.Item as={NavLink} to="/transaction/transaction-details">
              Payin Transaction Details
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title="Report"
            id="Thumb"
            className={
              drop1 == 1 ? "custom-dropdown active1" : "custom-dropdown"
            }
            activeClassName="active"
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            <NavDropdown.Item as={NavLink} to="/developer/upi-intent-report">
              UPI Intent Report
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/developer/upi-intent-report-2">
              UPI Intent Report 2
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/developer/payout">
              Pay Out Report
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/developer/payout">
              Payin Transaction Details
            </NavDropdown.Item>
          </NavDropdown>

          <NavLink
            onClick={logout}
            to="/"
            className="nav-link text-white  "
            style={{
              borderRadius: "5px",
              marginRight: "10px",
              marginLeft: "10px",
              backgroung: "white",
            }}
            activeClassName="active"
          >
            Logout
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
