import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel, now } from "../utils/utilFuctions";

import ComingSoonImage from "../../assets/animation/Coming Soon (1).json";
import Lottie from 'lottie-react';

import { SearchOutlined } from '@ant-design/icons';
import '../UpiIntentReport.css';
import {Pagination} from '../utils/Pagination';

import Select from "react-select";

const options = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "failed" },
  { label: "Virtual", value: "0" },
  { label: "Settlement Completed", value: "settlementcompleted" },
  { label: "Received", value: "received" },

]

export const columns = [
  {
    dataField: "merchantTransactionid",
    text: "Merchant Txnt ID",
    color: "#586275",
  },
  {
    dataField: "refid",
    text: "Ref id",
    color: "#586275",
  },
  {
    dataField: "utnno",
    text: "UTR No",
    color: "#586275",
  },
  {
    dataField: "transactiontype",
    text: "Transaction type",
    color: "#586275",
  },
  {
    dataField: "transactionAmount",
    text: "Amount",
    color: "#586275",
  },
  {
    dataField: "cgstAmount",
    text: "CGST Amount",
    color: "#586275",
  },
  {
    dataField: "sgstAmount",
    text: "SGST Amount",
    color: "#586275",
  },
  {
    dataField: "chargesAmount",
    text: "Charges",
    color: "#586275",
  },
  {
    dataField: "debitAmount",
    text: "Debit",
    color: "#586275",
  },
  {
    dataField: "creditAmount",
    text: "Credit",
    color: "#586275",
  },
  {
    dataField: "balance",
    text: "Balance",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#000",
  },

  {
    dataField: "createdOn",
    text: "Created On",
    color: "#586275",
  },
];

const Transactions = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [ledger, setLedger] = useState([]);

  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      "fromDate": dateFormatter(startDate),
      "toDate": dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        setLoading(false);

        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setLoading(false);

      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset-1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = (offset-1);
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage *offset -1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage,offset]);


  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset-1)) / offset));
    const startIdx = 0;
    const endIdx = offset -1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);

  console.log(ledger);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = ledger.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData)
    }
  }, [selectedStatus]);

  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <div className="col m-2">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `Transaction_Report_${now()}`,
                excelMapping(ledger, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>

        <div id='filters' style={{ gap: 3, display: 'flex' }}>

          <div id='searchBarStyle' className=" bg-light m-1 p-0 ">
            <SearchOutlined className=" m-0 p-2 " style={{ background: 'white', color: 'grey' }} />
            <input
              type="text"
              className="form-control"
              placeholder="Search text..."
              style={{ border: 'none' }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>
          <div className="col p-0 m-0" style={{ minWidth: '120px' }}>
            <Select
              options={options}
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e)
              }}
              style={{ display: 'flex', width: '100%', maxWidth: '50%', backgroundColor: 'red', border: 'none' }}
            />
          </div>
          <Form.Group className="p-0 m-0 " id='parentDatePicker' style={{ minWidth: '250px', display: 'flex', gap: 3 }}>
            <DatePicker
              className="form-control rounded-1 d-flex flex-grow-1"
              style={{ borderColor: '#ccc', }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
            />
            <DatePicker
              className="form-control rounded-1 d-flex flex-grow-1"
              style={{ borderColor: '#ccc', }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
            />
          </Form.Group>
          <div className="d-flex align-items-center" id='searchButton'>
            <Button
              className=""
              style={{ borderRadius: ".4rem", width: "7rem" }}
              variant="primary"
              onClick={getAllVirtualPayments}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </div>
      <div className="m-0 p-0 card  center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr style={{ height: "3rem" }}>
            <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
            {columns.map((heading, index) => (
              <th key={index} className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>{heading.text}</th>
            ))}
          </tr>
          {loading && (
            <div className="loader-container d-flex justify-content-center align-items-center">
              <div className="loader"></div>
            </div>
          )}
          {dataToDisplay.map((e, ind) => (
            <tr style={{ height: "3rem" }} key={ind}>
              <td className="text-center" style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td className="text-center" key={cell.dataField} style={{ fontSize: ".8rem", color: cell.color }}>
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() === "refunded" || e[cell.dataField].toString().toLowerCase() === "refund") ? (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) : (e[cell.dataField].toString().toLowerCase() == "settlementcompleted" || e[cell.dataField].toString().toLowerCase() == "success") ? (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) :
                        (e[cell.dataField] === "Received" || e[cell.dataField] === "Accepted") ? (
                          <label
                            style={{
                              padding: "8px",
                              borderRadius: ".4rem",
                              backgroundColor: 'rgba(255, 238, 0, 0.1)',
                              color: 'rgba(255, 196, 0, 1)',
                              border: "1px solid rgba(255, 238, 0, 1)",
                              borderColor: 'rgba(255, 238, 0, 1)',
                              fontSize: "0.8rem",
                            }}
                            className="badge badge-warning"
                          >
                            {e[cell.dataField]}
                          </label>
                        ) :
                          e[cell.dataField].toString().toLowerCase() === "failed" ? (
                            <label
                              style={{
                                padding: "8px",
                                borderRadius: ".4rem",
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                color: 'rgba(255, 0, 0, 1)',
                                border: "1px solid rgba(255, 0, 0, 1)",
                                borderColor: 'rgba(255, 0, 0, 1)',
                                fontSize: "0.8rem",
                              }}
                              className="badge badge-danger"
                            >
                              Failed
                            </label>
                          ) :
                            e[cell.dataField] === "0" ? (
                              <label
                                style={{
                                  padding: "8px",
                                  borderRadius: ".4rem",
                                  backgroundColor: 'rgba(0, 123, 255, 0.1)',
                                  color: 'rgba(0, 123, 255, 1)',
                                  border: "1px solid rgba(0, 123, 255, 1)",
                                  borderColor: 'rgba(0, 123, 255, 1)',
                                  fontSize: "0.8rem", textAlign: "center"
                                }}
                                className="badge badge-warning "
                              >
                                Virtual
                              </label>
                            ) : (
                              <label
                                style={{
                                  padding: "8px",
                                  borderRadius: ".4rem",
                                  backgroundColor: 'rgba(255, 238, 0, 0.1)',
                                  color: 'rgba(255, 196, 0, 1)',
                                  border: "1px solid rgba(255, 238, 0, 1)",
                                  borderColor: 'rgba(255, 238, 0, 1)',
                                  fontSize: "0.8rem", textAlign: "center"
                                }}
                                className="badge badge-warning "
                              >
                                {e[cell.dataField]}
                              </label>
                            )

                      }
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}

        </table>
        {ledger.length <= 0 && <NoDataFound />}
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page,offsetPassed) => {
          if(currentPage!=page)
            setCurrentPage(page);
        if(offset!= offsetPassed)
          setOffset(offsetPassed)
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};
export const Payin = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const { removeMyAccountInfo, // setTransactionLoaderState } = useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      // setTransactionLoaderState(true)
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        //   removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data
            .filter((acc) => {
              return Number(acc.creditAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      // setTransactionLoaderState(false)
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div div style={{ background: "white" }}>
      <div
        style={{
          background: "#fff"
        }}
      >
        <div
          className="row"
        >
          <div className="col-7 m-2">
            <div className="d-flex"
              style={{
                cursor: "pointer", border: "1px solid", height: "2.5rem", width: "11rem",
                backgroundColor: 'rgba(43, 193, 85, 0.1)',
                color: 'rgba(43, 193, 85, 1)',
                bordercolor: 'rgba(43, 193, 85, 1)', borderRadius: ".4rem"
              }}
              onClick={() => {
                ExportToExcel(
                  `PayIn_Report_${now()}`,
                  excelMapping(dataToDisplay, columns)
                );
              }}
            >
              <img alt=""
                src={require("../../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              />
              <strong className="d-flex align-items-center" style={{ marginLeft: "2px" }}>Export to Excel</strong>
            </div>
          </div>

          <div className=" col m-2 daterange-picker">
            <Form.Group >

              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
            <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>

              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>

            <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>

              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="End Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
            <div style={{ marginLeft: "10px", }} > <Button className="" style={{ borderRadius: ".4rem" }} variant="primary" onClick={() => { }}>
              {"Search"}
            </Button></div>
          </div>



        </div>

      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
            {columns.map((heading, index) => (
              <th key={index} className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>{heading.text}</th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td key={cell.dataField} style={{ fontSize: ".8rem", color: cell.color }}>
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() === "refunded" || e[cell.dataField].toString().toLowerCase() === "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField] === "SettlementCompleted" && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" || e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() === "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

export const Payout = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [ledger, setLedger] = useState([]);

  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      "fromDate": dateFormatter(startDate),
      "toDate": dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setLoading(true);
      // setTransactionLoaderState(true)
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        setLoading(false);

        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data

            .filter((acc) => {
              return Number(acc.debitAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setLoading(false);

      // setTransactionLoaderState(false)
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage *offset -1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage,offset]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset-1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = (offset-1);
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + (offset-1)) / offset));
    const startIdx = 0;
    const endIdx = offset -1;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      const filteredData = ledger.filter((e) => {
        return e.status.toString().toLowerCase() == selectedStatus.value;
      });
      setDataToDisplay(filteredData)
    }
  }, [selectedStatus]);

  console.log(ledger);
  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <div className="col m-2">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `PayOut_Report_${now()}`,
                excelMapping(dataToDisplay, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>
        <div id='filters' style={{ gap: 3, display: 'flex' }}>

          <div id='searchBarStyle' className=" bg-light m-1 p-0 ">
            <SearchOutlined className=" m-0 p-2 " style={{ background: 'white', color: 'grey' }} />
            <input
              type="text"
              className="form-control"
              placeholder="Search text..."
              style={{ border: 'none' }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>
          <div className="col p-0 m-0" style={{ minWidth: '120px' }}>
            <Select
              options={options}
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e)
              }}
              style={{ display: 'flex', width: '100%', maxWidth: '50%', backgroundColor: 'red', border: 'none' }}
            />
          </div>
          <Form.Group className="p-0 m-0 " id='parentDatePicker' style={{ minWidth: '250px', display: 'flex', gap: 3 }}>
            <DatePicker
              className="form-control rounded-1 d-flex flex-grow-1"
              style={{ borderColor: '#ccc', }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
            />
            <DatePicker
              className="form-control rounded-1 d-flex flex-grow-1"
              style={{ borderColor: '#ccc', }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
            />
          </Form.Group>
          <div className="d-flex align-items-center" id='searchButton'>
            <Button
              className=""
              style={{ borderRadius: ".4rem", width: "7rem" }}
              variant="primary"
              onClick={getAllVirtualPayments}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </div>
      <div className="m-0 p-0 card  center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr style={{ height: "3rem" }} >
            <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
            {columns.map((heading, index) => (
              <th key={index} className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>{heading.text}</th>
            ))}
          </tr>
          {loading && (
            <div className="loader-container d-flex justify-content-center align-items-center">
              <div className="loader"></div>
            </div>
          )} {dataToDisplay.map((e, ind) => (
            <tr style={{ height: "3rem" }} key={ind}>
              <td className="text-center" style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td className="text-center" key={cell.dataField} style={{ fontSize: ".8rem", color: cell.color }}>
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() === "refunded" || e[cell.dataField].toString().toLowerCase() === "refund") ? (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) : (e[cell.dataField].toString().toLowerCase() == "settlementcompleted" || e[cell.dataField].toString().toLowerCase() == "success") ? (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      ) :
                        (e[cell.dataField] === "Received" || e[cell.dataField] === "Accepted") ? (
                          <label
                            style={{
                              padding: "8px",
                              borderRadius: ".4rem",
                              backgroundColor: 'rgba(255, 238, 0, 0.1)',
                              color: 'rgba(255, 196, 0, 1)',
                              border: "1px solid rgba(255, 238, 0, 1)",
                              borderColor: 'rgba(255, 238, 0, 1)',
                              fontSize: "0.8rem",
                            }}
                            className="badge badge-warning"
                          >
                            {e[cell.dataField]}
                          </label>
                        ) :
                          e[cell.dataField].toString().toLowerCase() === "failed" ? (
                            <label
                              style={{
                                padding: "8px",
                                borderRadius: ".4rem",
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                color: 'rgba(255, 0, 0, 1)',
                                border: "1px solid rgba(255, 0, 0, 1)",
                                borderColor: 'rgba(255, 0, 0, 1)',
                                fontSize: "0.8rem",
                              }}
                              className="badge badge-danger"
                            >
                              Failed
                            </label>
                          ) :
                            e[cell.dataField] === "0" ? (
                              <label
                                style={{
                                  padding: "8px",
                                  borderRadius: ".4rem",
                                  backgroundColor: 'rgba(0, 123, 255, 0.1)',
                                  color: 'rgba(0, 123, 255, 1)',
                                  border: "1px solid rgba(0, 123, 255, 1)",
                                  borderColor: 'rgba(0, 123, 255, 1)',
                                  fontSize: "0.8rem", textAlign: "center"
                                }}
                                className="badge badge-warning "
                              >
                                Virtual
                              </label>
                            ) : (
                              <label
                                style={{
                                  padding: "8px",
                                  borderRadius: ".4rem",
                                  backgroundColor: 'rgba(255, 238, 0, 0.1)',
                                  color: 'rgba(255, 196, 0, 1)',
                                  border: "1px solid rgba(255, 238, 0, 1)",
                                  borderColor: 'rgba(255, 238, 0, 1)',
                                  fontSize: "0.8rem", textAlign: "center"
                                }}
                                className="badge badge-warning "
                              >
                                {e[cell.dataField]}
                              </label>
                            )

                      }
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
        {ledger.length <= 0 && <NoDataFound />}
       
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page,offsetPassed) => {
          if(currentPage!=page)
            setCurrentPage(page);
        if(offset!= offsetPassed)
          setOffset(offsetPassed)
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

export const Ledger = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const { removeMyAccountInfo, // setTransactionLoaderState } = useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      // setTransactionLoaderState(true)
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      // setTransactionLoaderState(false)
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      // setTransactionLoaderState(false)
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div style={{ background: "white" }}>

      <div
        className="row"
      >
        <div className="col-7 m-2">
          <div className="d-flex"
            style={{
              cursor: "pointer", border: "1px solid", height: "2.5rem", width: "11rem",
              backgroundColor: 'rgba(43, 193, 85, 0.1)',
              color: 'rgba(43, 193, 85, 1)',
              bordercolor: 'rgba(43, 193, 85, 1)', borderRadius: ".4rem"
            }}
            onClick={() => {
              ExportToExcel(
                `Ledger_Report_${now()}`,
                excelMapping(dataToDisplay, columns)
              );
            }}
          >
            <img alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong className="d-flex align-items-center" style={{ marginLeft: "2px" }}>Export to Excel</strong>
          </div>
        </div>

        <div className=" col m-2 daterange-picker">
          <Form.Group >
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>
          <div style={{ marginLeft: "10px", }} > <Button className="" style={{ borderRadius: ".4rem" }} variant="primary" onClick={() => { }}>
            {"Search"}
          </Button></div>
        </div>



      </div>


      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
            {columns.map((heading, index) => (
              <th key={index} className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>{heading.text}</th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td key={cell.dataField} style={{ fontSize: ".8rem", color: cell.color }}>
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() === "refunded" || e[cell.dataField].toString().toLowerCase() === "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField] === "SettlementCompleted" && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                            color: 'rgba(43, 193, 85, 1)',
                            borderColor: 'rgba(43, 193, 85, 1)',
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" || e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() === "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            color: 'rgba(0, 123, 255, 1)',
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: 'rgba(0, 123, 255, 1)',
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
       
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};



export default Transactions;

export const NoDataFound = () => {
  return (   
      <div className="d-flex flex-column align-items-center justify-content-center p-3">
        <Lottie
         animationData={ComingSoonImage}
         alt="Coming Soon"
         style={{ height: "40vh", width: "100vw" }}
        />
        <strong>No Data Found</strong>
      </div>
    //   </td>
    // </tr>
  );
};
