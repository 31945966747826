import React, { useState } from "react";
import { Button } from "react-bootstrap";

export const Pagination = ({ page, limit, callback, total, count }) => {
  const [itemsPerPage, setItemsPerPage] = useState(limit);
  const [currentPage, setCurrentPage] = useState(page);

  const totalPages = Math.ceil(total / itemsPerPage);

  const offsetArr = [10, 20, 30, 40, 50,500,1000,5000, 10000]; // Define your offset array

  const handleOffsetChange = (offset) => {
    setItemsPerPage(offset);
    setCurrentPage(1);
    callback(1, offset);
  };

  return (
    <div
      className="row d-flex align-items-center"
      style={{
        width: "100%",
        margin: '10px',
      }}
    >
      <div style={{ display: "flex", flex: 2 }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex" style={{ flex: 0 }}>
        <select
          className="form"
          aria-label="offset"
          value={itemsPerPage}
          onChange={(e) => handleOffsetChange(parseInt(e.target.value, 10))}
        >
          {offsetArr.map((offset) => (
            <option key={offset} value={offset}>
              {offset}
            </option>
          ))}
        </select>
        {currentPage - 2 > 0 && (
          <Button
            onClick={() => {
              setCurrentPage(currentPage - 1);
              callback(currentPage - 1, itemsPerPage);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        )}
        {currentPage - 1 > 0 && (
          <Button
            onClick={() => {
              setCurrentPage(currentPage - 1);
              callback(currentPage - 1, itemsPerPage);
            }}
            variant="light"
          >
            {(currentPage - 1).toString()}
          </Button>
        )}
        <Button variant="primary">{currentPage.toString()}</Button>
        {currentPage < totalPages && (
          <Button
            onClick={() => {
              setCurrentPage(currentPage + 1);
              callback(currentPage + 1, itemsPerPage);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        )}
      </div>
    </div>
  );
};
