import React from "react";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import "./Loader.css";
import axios from "axios";
import { Pagination } from "./utils/Pagination";
import Swal from "sweetalert2";

import ComingSoonImage from "../assets/animation/Coming Soon (1).json";
import Lottie from "lottie-react";
import { Button } from "react-bootstrap";
import { ExportToExcel, excelMapping, now } from "./utils/utilFuctions";

export const columns = [
  {
    dataField: "id",
    text: "ID",
    color: "#586275",
  },
  {
    dataField: "fk_user_id",
    text: "User ID",
    color: "#586275",
  },
  {
    dataField: "payin_amount",
    text: "Payin Amount",
    color: "#586275",
  },
  {
    dataField: "payin_charge",
    text: "Payin Charge",
    color: "#586275",
  },
  {
    dataField: "payin_gst",
    text: "Payin GST",
    color: "#586275",
  },
  {
    dataField: "settlement_time",
    text: "Settlement Time",
    color: "#586275",
  },
  {
    dataField: "indate",
    text: "In Date",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#586275",
  },
];

const SettlementPay = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const [endDate, setEndDate] = useState(new Date());
  const [settlementButton, setsettlementButton] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const location = useLocation();
  const InternalMerchentID = new URLSearchParams(location.search).get(
    "InternalMerchentID"
  );
  const InternalMerchentName = new URLSearchParams(location.search).get(
    "InternalMerchentName"
  );

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  //Settlement Report of Client
  const reportApi = async () => {
    setLoading(true);
    const rawData = {
      //"merchantId": "2",
      merchantId: InternalMerchentID,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        `https://cubexis.in/api/sdkClientSettlementRecords.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data); // Assuming the data property holds the report data
        setsettlementButton("true");
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  const updateSettlementStatus = async (ledgerId, status) => {
    console.log("ledgerId", ledgerId, "status", status);
    try {
      let apiStatus = status === "SETTLED" ? "UNSETTLED" : "SETTLED";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://payin.remittpe.com/api/Intent/updatePayinSettlement",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const calculateSettlement = async (ledgerId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: "",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://cubexis.in/api/calculatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const initiateSettlement = async (ledgerId, settlement, status) => {
    try {
      let apiStatus = status === "N" ? "Y" : "N";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        settlement: settlement,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://cubexis.in/api/initiatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status == "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
      // ViewUPIIntentReport();
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchant_id: InternalMerchentID,
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/GetMerchantLedgerReport`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
        setDataToDisplay(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    ViewUPIIntentReport();
  }, []);

  //Pay All Settlement Api
  const PayAllApi = async () => {
    setLoading(true);
    const rawData = {
      //   merchantId: "2",
      merchantId: InternalMerchentID,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        ` https://cubexis.in/api/sdkClientSettlementAdmin.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data); // Assuming the data property holds the report data
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the API with the selected date range

    reportApi();
  };

  const positionStyle = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  return (
    <div
      className=" p-4"
      style={{ backgroundColor: "#F1EFEF", fontFamily: "poppins" }}
    >
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">Please wait...</div>
        </div>
      )}
      <div
        className="full-width-container text-dark p-3 mt-4 shadow "
        style={{
          backgroundColor: "#fff",
          border: "0px solid #000000",
          borderTop: "0px solid #000000",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <form onSubmit={handleSubmit} className="row  ">
          <div className="mb-3 col-12 col-lg-6 d-flex align-items-center justify-content-center text-center">
            <div
              className="d-flex"
              style={{
                padding: "0px 10px",
                cursor: "pointer",
                border: "1px solid",
                height: "2.5rem",
                width: "11rem",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                bordercolor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
              }}
              onClick={() => {
                ExportToExcel(
                  `PAYIN_LEDGER_REPORT_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                alt=""
                src={require("../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              />
              <strong
                className="d-flex align-items-center"
                style={{ marginLeft: "2px" }}
              >
                Export to Excel
              </strong>
            </div>
            <div className="" style={{ fontSize: "28px" }}>
              {" "}
              Settlement Pay :{" "}
              <span className="text-success">{InternalMerchentName}</span>
            </div>
          </div>

          <div className="mb-3 col-12 col-lg-2">
            <label className="form-label">Start Date</label>
            <DatePicker
              className="p-1 "
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="mb-3 col-12 col-lg-2">
            <label className="form-label">End Date</label>
            <DatePicker
              className="p-1 "
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
          <div className="mt-4 col-12 col-lg-2">
            <button
              type="submit"
              className=" btn btn btn-block btn-primary"
              style={{ width: "100%" }}
            >
              Search
            </button>
          </div>
          {settlementButton && (
            <>
              <div className="mt-4 col-12 col-lg-2">
                <button
                  onClick={PayAllApi()}
                  className=" btn btn-lg btn-block btn-danger"
                  style={{ width: "100%" }}
                >
                  All Settlement
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      <div
        className=" bg-white p-2 "
        style={{ border: "1px solid rgba(0,0,0,1)", borderRadius: "15px" }}
      >
        <table className="table" style={{ fontFamily: "poppins" }}>
          <thead>
            <tr>
              <th
                style={{ borderRadius: "15px 0px 0px 0px" }}
                className=" text-white text-center py-3 bg-primary"
              >
                {" "}
                Id
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Payin Amount
              </th>
              <th className="bg-primary text-white text-center py-3">
                Payin Charge
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Payin Gst
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Settlement Amount
              </th>
              {/* <th className="bg-primary text-white text-center py-3 ">
                Settlement Amount 1
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Settlement Amount 2
              </th> */}
              <th className="bg-primary text-white text-center py-3 ">
                Transaction time
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Settlement time
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Last Updated
              </th>
              <th className="bg-primary text-white text-center py-3 ">
                Status
              </th>
              {/* <th className="bg-primary text-white text-center py-3">
                Settlement 1
              </th>
              <th className="bg-primary text-white text-center py-3">
                Settlement 2
              </th> */}
              <th className="bg-primary text-white text-center py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : (
              dataToDisplay.map((item) => (
                <tr key={item.UserName}>
                  <td className="text-center align-middle">
                    {item.id || "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.payin_amount || "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.payin_charge !== undefined
                      ? parseFloat(item.payin_charge).toFixed(2)
                      : "NA"}
                  </td>

                  <td className="text-center align-middle">
                    {item.payin_gst !== undefined
                      ? parseFloat(item.payin_gst).toFixed(2)
                      : "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.settlementAmount !== undefined
                      ? parseFloat(item.settlementAmount).toFixed(2)
                      : "NA"}
                  </td>
                  {/* <td className="text-center align-middle">
                    {item.settlementAmount !== undefined
                      ? parseFloat(item.settlement_1).toFixed(2)
                      : "NA"}
                  </td>
                  <td className="text-center align-middle">
                    {item.settlementAmount !== undefined
                      ? parseFloat(item.settlement_2).toFixed(2)
                      : "NA"}
                  </td> */}
                  <td className="text-center align-middle">
                    {item.settlement_time}
                  </td>
                  <td className="text-center align-middle">
                    {item.settlement_time == "5PM-8AM"
                      ? "03:00 PM"
                      : "11:00 PM"}
                  </td>
                  <td className="text-center align-middle">{item.indate}</td>
                  <td className="text-center align-middle">
                    {" "}
                    {item.status !== undefined &&
                    item.status == "SETTLED" ? (
                      <label
                        style={{
                          padding: "8px",
                          border: "1px solid",
                          height: "2rem",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          borderColor: "rgba(43, 193, 85, 1)",
                          borderRadius: ".4rem",
                          fontSize: ".8rem",
                        }}
                        className="badge badge-success"
                      >
                        {"Settled"}
                      </label>
                    ) : item.status !== undefined &&
                      item.status == "UNSETTLED" ? (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                          color: "rgba(255, 0, 0, 1)",
                          border: "1px solid rgba(255, 0, 0, 1)",
                          borderColor: "rgba(255, 0, 0, 1)",
                          fontSize: "0.8rem",
                        }}
                        className="badge badge-danger"
                      >
                        {"Unsettled"}
                      </label>
                    ) : (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          backgroundColor: "rgba(255, 238, 0, 0.1)",
                          color: "rgba(255, 196, 0, 1)",
                          border: "1px solid rgba(255, 238, 0, 1)",
                          borderColor: "rgba(255, 238, 0, 1)",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                        className="badge badge-warning "
                      >
                        {"Unsettled"}
                      </label>
                    )}
                  </td>
                  {/* <td className="text-center align-middle">
                    {" "}
                    {item.settlement_status_1 !== undefined &&
                    item.settlement_status_1 == "Y" ? (
                      <label
                        style={{
                          padding: "8px",
                          border: "1px solid",
                          height: "2rem",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          borderColor: "rgba(43, 193, 85, 1)",
                          borderRadius: ".4rem",
                          fontSize: ".8rem",
                        }}
                        className="badge badge-success"
                      >
                        {"Settled"}
                      </label>
                    ) : item.settlement_status_1 !== undefined &&
                      item.settlement_status_1 == "N" ? (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                          color: "rgba(255, 0, 0, 1)",
                          border: "1px solid rgba(255, 0, 0, 1)",
                          borderColor: "rgba(255, 0, 0, 1)",
                          fontSize: "0.8rem",
                        }}
                        className="badge badge-danger"
                      >
                        {"Unsettled"}
                      </label>
                    ) : (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          backgroundColor: "rgba(255, 238, 0, 0.1)",
                          color: "rgba(255, 196, 0, 1)",
                          border: "1px solid rgba(255, 238, 0, 1)",
                          borderColor: "rgba(255, 238, 0, 1)",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                        className="badge badge-warning "
                      >
                        {"Unsettled"}
                      </label>
                    )}
                  </td>

                  <td className="text-center align-middle">
                    {" "}
                    {item.settlement_status_2 !== undefined &&
                    item.settlement_status_2 == "Y" ? (
                      <label
                        style={{
                          padding: "8px",
                          border: "1px solid",
                          height: "2rem",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          borderColor: "rgba(43, 193, 85, 1)",
                          borderRadius: ".4rem",
                          fontSize: ".8rem",
                        }}
                        className="badge badge-success"
                      >
                        {"Settled"}
                      </label>
                    ) : item.settlement_status_2 !== undefined &&
                      item.settlement_status_2 == "N" ? (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                          color: "rgba(255, 0, 0, 1)",
                          border: "1px solid rgba(255, 0, 0, 1)",
                          borderColor: "rgba(255, 0, 0, 1)",
                          fontSize: "0.8rem",
                        }}
                        className="badge badge-danger"
                      >
                        {"Unsettled"}
                      </label>
                    ) : (
                      <label
                        style={{
                          padding: "8px",
                          borderRadius: ".4rem",
                          backgroundColor: "rgba(255, 238, 0, 0.1)",
                          color: "rgba(255, 196, 0, 1)",
                          border: "1px solid rgba(255, 238, 0, 1)",
                          borderColor: "rgba(255, 238, 0, 1)",
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                        className="badge badge-warning "
                      >
                        {"Unsettled"}
                      </label>
                    )}
                  </td> */}

                  <td className="text-center align-middle">
                    <Button
                      className="btn btn-primary"
                      onClick={() =>
                        updateSettlementStatus(item.id, item.status)
                      }
                    >
                      {item.status === "SETTLED" ? "UNSETTLE" : "SETTLE"}
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      onClick={() => {
                        calculateSettlement(item.id);
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        <a>
                          <img
                            src={require("../assets/images/Update Access.png")}
                            alt="Clickable Image"
                          />
                        </a>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        {/* <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          calculate settlement
                        </div> */}
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      onClick={() => {
                        initiateSettlement(
                          item.id,
                          "1",
                          item.settlement_status_1
                        );
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        <a>
                          <img
                            src={require("../assets/images/Rehit.png")}
                            alt="Clickable Image"
                          />
                        </a>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        {/* <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          release settlement 1
                        </div> */}
                      </div>
                    </Button>
                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      onClick={() => {
                        initiateSettlement(
                          item.id,
                          "2",
                          item.settlement_status_2
                        );
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        <a>
                          <img
                            src={require("../assets/images/Rehit.png")}
                            alt="Clickable Image"
                          />
                        </a>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        {/* <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          release settlement 2
                        </div> */}
                      </div>
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {data.length <= 0 && <NoDataFound />}
        <hr />
        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page, offsetPassed) => {
            if (currentPage != page) setCurrentPage(page);
            if (offset != offsetPassed) setOffset(offsetPassed);
          }}
          count={dataToDisplay.length}
          total={total}
        />
      </div>
    </div>
  );
};

export default SettlementPay;

export const NoDataFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-3">
      <Lottie
        animationData={ComingSoonImage}
        alt="Coming Soon"
        style={{ height: "40vh", width: "100vw" }}
      />
      <strong>No Data Found</strong>
    </div>
  );
};
