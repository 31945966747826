import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAuth } from "../../context/AuthContext";
function TransactionDetails() {
  const { user, setTransactionLoaderState } = useAuth();
  const [passwordError, setPasswordError] = useState("");
  const [data, setData] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });

  const [formError, setFormError] = useState({
    businessname: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const clearForm = () => {
    setFormData({
      businessname: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobile: "",
    });
    setPasswordError("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password != formData.confirmPassword) {
      setFormError({ ...formError, password: ["Password does not match"] });
      return;
    }
    // clearForm();
    createMerchantApi();
  };

  const createMerchantApi = async () => {
    console.log("hiii");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        txnId: formData.txnid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://cubexis.in/api/getTransactionDetails.php",
        requestOptions
      );

      const data = await res.json();

      console.log(data);

      if (data.status === "00") {
        setTransactions(data.details);
      } else {
        if (data.errors) {
          setFormError(data.errors);
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching Form data:", error);
    }
  };

  return (
    <div
      className=" p-0 p-lg-5"
      style={{
        backgroundColor: "#F1EFEF",
        fontFamily: "poppins",
        height: "93vh",
      }}
    >
      <div
        className="full-width-container text-dark bg -light p-3 m-3  "
        style={{
          border: "0px solid #000000",
          borderTop: "0px solid #000000",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <div className="row p-0  p-lg-5">
          <div className="col-3"></div>
          <div
            className="col-12 p-3 p-lg-4 col-lg-6 bg-white shadow"
            style={{ borderRadius: "10px" }}
          >
            <form className="row">
              <div className="mb-3 col-12  d-flex align-items-center justify-content-center text-center">
                <div
                  className=""
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    fontFamily: "poppins",
                  }}
                >
                  {" "}
                  Transaction Details
                </div>
              </div>

              <div className="col-12 ">
                <label className="form-label">
                  RefId or RRN{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.txnid}
                  placeholder="Enter RefId or RRN"
                  onChange={(e) =>
                    setFormData({ ...formData, txnid: e.target.value })
                  }
                  required
                />
                {formError.txnid && (
                  <p style={{ color: "red" }} className="error-message">
                    {formError.txnid}
                  </p>
                )}
              </div>

              <div className="row">
                <div className="row mt-4">
                  <div className="col">
                    {" "}
                    <button
                      className=" btn btn-block  text-white bg-success m-2"
                      style={{
                        width: "100%",
                      }}
                      onClick={handleSubmit}
                    >
                      Fetch Transaction Details
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          { transactions.length>0 && (
            <div className="container mt-5">
            <h2 className="mb-4">Transaction Details</h2>
            {transactions.map((transaction, index) => (
              <table
                className="table table-bordered table-striped mb-4"
                key={index}
              >
                <tbody>
                  <tr>
                    <th>Intent ID</th>
                    <td>{transaction.pk_intent_id}</td>
                  </tr>
                  <tr>
                    <th>User ID</th>
                    <td>{transaction.fk_user_id}</td>
                  </tr>
                  <tr>
                    <th>Reference ID</th>
                    <td>{transaction.ref_id}</td>
                  </tr>
                  <tr>
                    <th>Opening Balance</th>
                    <td>{transaction.opening_bal}</td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>{transaction.amount}</td>
                  </tr>
                  <tr>
                    <th>Charge</th>
                    <td>{transaction.charge}</td>
                  </tr>
                  <tr>
                    <th>GST</th>
                    <td>{transaction.GST}</td>
                  </tr>
                  <tr>
                    <th>Closing Balance</th>
                    <td>{transaction.closing_bal}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{transaction.status}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>{transaction.date}</td>
                  </tr>
                  <tr>
                    <th>VPA</th>
                    <td>{transaction.vpa}</td>
                  </tr>
                  <tr>
                    <th>Customer ID</th>
                    <td>{transaction.cust_id}</td>
                  </tr>
                  <tr>
                    <th>Customer Name</th>
                    <td>{transaction.custName}</td>
                  </tr>
                  <tr>
                    <th>Customer Mobile</th>
                    <td>{transaction.custMobile}</td>
                  </tr>
                  <tr>
                    <th>RRN</th>
                    <td>{transaction.rrn}</td>
                  </tr>
                  <tr>
                    <th>PayU ID</th>
                    <td>{transaction.payu_id ? transaction.payu_id : "N/A"}</td>
                  </tr>
                  <tr>
                    <th>Merchant ID</th>
                    <td>{transaction.mid}</td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
