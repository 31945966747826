// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";

import PropTypes from "prop-types";

const AuthContext = createContext();

const storedUser = JSON.parse(sessionStorage.getItem("user"));
const storedUser2 = JSON.parse(sessionStorage.getItem("user2"));

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(storedUser ? storedUser : null);
  const [user2, setUser2] = useState(storedUser2 ? storedUser2 : null);
  const [loaderState, setLoaderState] = useState(false);

  const [apiBaseUrl, setApiBaseUrl] = useState("");

  const login = (userCredentials) => {
    // Store the user data in localStorage
    sessionStorage.setItem("user", JSON.stringify(userCredentials));
    setUser(userCredentials);
  };

  const login2 = (userCredentials) => {
    // Store the user data in localStorage
    sessionStorage.setItem("user2", JSON.stringify(userCredentials));
    setUser2(userCredentials);
  };

  const logout = () => {
    // Remove the user data from localStorage and set user state to null
    sessionStorage.removeItem("user");
    setUser(null);
  };

  const setTransactionLoaderState = (loader) => {
    setLoaderState(loader);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        user2,
        login2,
        login,
        logout,
        apiBaseUrl,
        setApiBaseUrl,
        loaderState,
        setTransactionLoaderState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => React.useContext(AuthContext);
