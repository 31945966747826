import React from "react";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Loader.css";
import { Button } from "react-bootstrap";
import { urls } from "./api_partner/devUrls";
import { useHistory } from "react-router-dom";
const SettlementProcess = () => {
  const history = useHistory();
  const { user, login2, user2 , setTransactionLoaderState } = useAuth();
  const { login: loginUrl } = urls;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [allMerchantList, setallMerchantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { GetUserProfileInfo: GetUserProfileInfoUrl } = urls;
  const AllMerchentListAPi = async () => {
    // setLoading(true);
    const rawData = {
      merchantId: user.merchantId,
    };

    try {
      setTransactionLoaderState(true);
      const response = await axios.post(
        `https://cubexis.in/api/sdkClientGetAllMerchants.php`,
        rawData
      );

      if (response.status === 200) {
        setallMerchantList(response.data.data);
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching report data:", error);
    } finally {
      setTransactionLoaderState(false);
      setLoading(false);
    }
  };

  // get my account details
  const getAccountInfo = async (merchant) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      const res = await fetch(GetUserProfileInfoUrl, requestOptions);
      const data = await res.json();
      login2(data.data);
      if (data.data) {
        sessionStorage.setItem("merchant", JSON.stringify(merchant));
        history.push(`developer/payout`);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const login = async (merchant) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
    );

    var raw = JSON.stringify({
      email: merchant.payout_username,
      password: merchant.payout_password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      const result = await fetch(loginUrl, requestOptions);
      if (result.ok) {
        const body = await result.text();
        const data = JSON.parse(body);
        if (data.userName != undefined) {
          sessionStorage.setItem("userName", data.userName);
        }
        if (data.role != undefined) {
          sessionStorage.setItem("role", data.role);
        }
        if (data.accessToken != undefined && data.userId != undefined) {
          sessionStorage.setItem("userId", data.userId);
          // setToken(data.accessToken);
          sessionStorage.setItem("accessToken", data.accessToken);
          getAccountInfo(merchant);
        }
        if (data.refreshToken != undefined) {
          sessionStorage.setItem("refreshToken", data.refreshToken);
        }
        sessionStorage.setItem(
          "basic_auth",
          JSON.stringify({
            username: merchant.payout_username,
            password: merchant.payout_password,
          })
        );
        //  setRedirect(true);
        //  setInvalid(false);
        setTransactionLoaderState(false);
      } else {
        //  setInvalid(true);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log(error);
    }
  };

  

  // Call the API on page load
  useEffect(() => {
    AllMerchentListAPi();
  }, []);

  return (
    <div
      className=" p-4"
      style={{ backgroundColor: "#F1EFEF", fontFamily: "poppins" }}
    >
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">Please wait...</div>
        </div>
      )}
      <div
        className="full-width-container text-dark p-3 mt-4 shadow  "
        style={{
          backgroundColor: "#fff",
          border: "0px solid #000000",
          borderTop: "0px solid #000000",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <div className="mb-3  d-flex align-items-center justify-content-center text-center">
          <div className="" style={{ fontSize: "32px" }}>
            {" "}
            Settlement Process
          </div>
        </div>
      </div>

      <div className="pt-5 ">
        <div
          className=" bg-white shadow"
          style={{ border: "1px solid rgba(0,0,0,1)", borderRadius: "15px" }}
        >
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr>
                <th
                  style={{ borderRadius: "15px 0px 0px 0px" }}
                  className=" text-white text-center py-3 bg-primary"
                >
                  Sr
                </th>

                <th className="bg-primary text-white text-center py-3">
                  Business Name
                </th>

                <th
                  style={{ borderRadius: "0px 15px 0px 0px" }}
                  className="bg-primary text-center py-3 text-white "
                >
                  Pay
                </th>
              </tr>
            </thead>

            <tbody>
              {allMerchantList.map((item, index) => (
                <>
                  <tr className="shadow" key={item._id}>
                    <td
                      className="text-center py-3"
                      style={{ backgroundColor: "" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      className="text-center py-3"
                      style={{ backgroundColor: "" }}
                    >
                      {item.BusinessName}{" "}
                    </td>
                    <td
                      className="text-center py-3"
                      style={{ backgroundColor: "" }}
                    >
                      <Link
                        to={`/SettlementPay?InternalMerchentID=${item.merchantId}&&InternalMerchentName=${item.BusinessName}`}
                      >
                        <Button
                          className="shadow"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            color: "rgba(43, 193, 85, 1)",
                            marginRight: "10px",
                          }}
                        >
                          Settlement <span> {"➜"}</span>
                        </Button>
                      </Link>

                      <Link
                        to={`/developer/upi-intent-report-2?InternalMerchentID=${item.merchantId}`}
                      >
                        <Button
                          className="shadow"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            color: "rgba(43, 193, 85, 1)",
                            marginRight: "10px",
                          }}
                        >
                          Payin Report <span> {"➜"}</span>
                        </Button>
                      </Link>

                      {/* <Link
                      to={`/developer/upi-intent-report?InternalMerchentID=${item.merchantId}`}
                    > */}
                      <Button
                        className="shadow"
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          borderColor: "rgba(43, 193, 85, 1)",
                          color: "rgba(43, 193, 85, 1)",
                        }}
                        onClick={() => {
                          login(item);
                        }}
                      >
                        Payout Report <span> {"➜"}</span>
                      </Button>
                      {/* </Link> */}
                    </td>
                  </tr>
                  <td colSpan={6} style={{ height: "15px" }}></td>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SettlementProcess;
