import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
const arr = [{ label: "Name", value: "Name" }];

const ViewAllMerchant = () => {
    const {user} = useAuth();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [loading, setLoading] = useState(true);
  const ViewAllMerchant = async () => {
    console.log(ViewAllMerchant, "shee");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://cubexis.in/api/getAllChildMerchant.php`,
        requestOptions
      );
      const data = await res.json();
      console.log("Merchant", data);
      if (data.status === "00") {
        setData(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleDetailsClick = (merchant) => {
    setSelectedMerchant(merchant);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedMerchant(null);
    setShowModal(false);
  };
  useEffect(() => {
    ViewAllMerchant();
  }, []);

  return (
    <>
      <div className="p-2 ">
        <h4>View All Merchant</h4>
        <div
          className=" bg-white "
          style={{ border: "1px solid rgba(0,0,0,1)", borderRadius: "15px" }}
        >
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr>
                <th
                  style={{ borderRadius: "15px 0px 0px 0px" }}
                  className=" text-white text-center py-3 bg-primary"
                >
                  Merchant Id
                </th>

                <th className="bg-primary text-white text-center py-3">
                  {" "}
                  UserName
                </th>
                <th className="bg-primary text-white text-center py-3">
                  Business
                </th>
                <th className="bg-primary text-white text-center py-3 ">
                  Bank
                </th>
                <th className="bg-primary text-white text-center py-3 ">
                  Account
                </th>
                <th className="bg-primary text-white text-center py-3">IfSC</th>

                <th className="bg-primary text-white text-center py-3">
                  Balance
                </th>
                <th className="bg-primary text-white text-center py-3">
                  Virtual Balance
                </th>
                <th
                  style={{ borderRadius: "0px 15px 0px 0px" }}
                  className="bg-primary text-center py-3 text-white text-center py-3"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                data.map((item) => (
                  <tr key={item.UserName}>
                    <td className="text-center align-middle">
                      {item.merchantId || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.UserName || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.BusinessName || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.BankName || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.AccountNumber || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.IfscCode || "NA"}
                    </td>

                    <td className="text-center align-middle">
                      {item.PaymentAPIBalance || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.virtualBalance || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      <button
                        type="button"
                        className="btn btn-primary px-2"
                        onClick={() => handleDetailsClick(item)}
                      >
                        Details
                      </button>
                      <div className="m-2">

                      </div>
                      <button
                        type="button"
                        className="btn btn-primary px-2"
                        onClick={() =>
                          history.push(`/merchant/set-commercials?id=${item.merchantId}`)
                        }
                      >
                        Set Commercials
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Body>
            {selectedMerchant && (
              <div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <label htmlFor="merchantId" className="form-label">
                      Merchant:
                    </label>
                  </div>
                  <div className="col">
                    {" "}
                    <div>{selectedMerchant.merchantId || "NA"}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <label htmlFor="merchantId" className="form-label">
                      Merchant Secret:
                    </label>
                  </div>
                  <div className="col">
                    <div>{selectedMerchant.merchantSecret || "NA"}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {" "}
                    <label htmlFor="merchantId" className="form-label">
                      Password Plain:
                    </label>
                  </div>
                  <div className="col">
                    <div>{selectedMerchant.password_plain || "NA"}</div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAllMerchant;
